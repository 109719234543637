import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_ERROR,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_LOADING,
  ADD_NOTIFICATION_TO_STORE,
  DELETE_NOTIFICATION_FROM_STORE
} from "constants/ActionTypes";

export const getNotifications = () => {
  return {
    type: GET_NOTIFICATIONS
  };
};

export const getNotificationsLoading = value => {
  return {
    type: GET_NOTIFICATIONS_LOADING,
    payload: value
  };
};

export const getNotificationsError = error => {
  return {
    type: GET_NOTIFICATIONS_ERROR,
    payload: error
  };
};

export const getNotificationsSuccess = (notificationsList, notificationsMap) => {
  return {
    type: GET_NOTIFICATIONS_SUCCESS,
    payload: {
      notificationsList,
      notificationsMap
    }
  };
};

export const addNotificationToStore = notificationObject => {
  return {
    type: ADD_NOTIFICATION_TO_STORE,
    payload: notificationObject,
  };
}

export const deleteNotificationFromStore = notificationObject => {
  return {
    type: DELETE_NOTIFICATION_FROM_STORE,
    payload: notificationObject,
  };
}