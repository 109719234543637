import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GET_NOTIFICATIONS } from "../constants/ActionTypes";
import {
  getNotificationsError,
  getNotificationsSuccess
} from "actions/Notifications";
import FirestoreService from "../services/firestoreService";

function* getNotificationsHelper() {
  try {
    const result = yield call(FirestoreService.getNotifications);
    if (result.data !== undefined) {
      // It means there is  data to display
      yield put(getNotificationsSuccess(result.data));
    } else if (result.noData !== undefined) {
      // It means there is no data to display
      // console.log(result.noData);
      yield put(getNotificationsError(result.noData));
    } else {
      // It means there is error
      // console.log(result.error);
      yield put(getNotificationsError(result.error));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getNotificationsSaga() {
  yield takeEvery(GET_NOTIFICATIONS, getNotificationsHelper);
}

export default function* rootSaga() {
  yield all([fork(getNotificationsSaga)]);
}
