import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GET_ILLNESS_LIST } from "../constants/ActionTypes";
import { getIllnessError, getIllnessSuccess } from "actions";
import FirestoreService from "services/firestoreService";

function* getIllnessListHelper() {
  try {
    console.log('Get illness sage helper');
    
    const result = yield call(FirestoreService.getIllnessList);
    if (result.data !== undefined) {
      // It means there is  data to display
      yield put(getIllnessSuccess(result.data));
      console.log(result.data);
      
    } else if (result.noData !== undefined) {
      // It means there is no data to display
      console.log(result.noData);
      yield put(getIllnessError(result.noData));
    } else {
      // It means there is error
      console.log(result.error);
      yield put(getIllnessError(result.error));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getIllnessListSaga() {
  yield takeEvery(GET_ILLNESS_LIST, getIllnessListHelper);
}

export default function* rootSaga() {
  yield all([fork(getIllnessListSaga)]);
}
