import {
  GET_NOTIFICATIONS_LOADING,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_ERROR,
  ADD_NOTIFICATION_TO_STORE,
  DELETE_NOTIFICATION_FROM_STORE
} from "../constants/ActionTypes";

const INITIAL_STATE = {
  notificationsList: [],
  notificationsMap: {},
  loading: false,
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_LOADING:
      return {
        ...state,
        loading: action.payload
      };
      

    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationsList: action.payload.notificationsList,
        notificationsMap: action.payload.notificationsMap
      };
      

    case GET_NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
      

    case ADD_NOTIFICATION_TO_STORE:
      return {
        ...state,
        notificationsList: [...state.notificationsList, action.payload]
      };
      

    case DELETE_NOTIFICATION_FROM_STORE:
      return {
        ...state,
        notificationsList: state.notificationsList.filter(elem => {
          return elem.uid !== action.payload.uid;
        })
      };
      

    default:
      return state;
  }
};
