import {
  ADD_ILLNESS,
  REMOVE_ILLNESS,
  GET_ILLNESS_LIST,
  GET_ILLNESS_LIST_LOADING,
  GET_ILLNESS_LIST_ERROR,
  GET_ILLNESS_LIST_SUCCESS,
} from "constants/ActionTypes";

export const getIllness = () => {
  console.log('get illness acction');
  
  return {
    type: GET_ILLNESS_LIST,
  };
};

export const getIllnessLoading = (value) => {
  return {
    type: GET_ILLNESS_LIST_LOADING,
    payload: value,
  };
};

export const getIllnessError = (error) => {
  return {
    type: GET_ILLNESS_LIST_ERROR,
    payload: error,
  };
};

export const getIllnessSuccess = (illnessList) => {
  return {
    type: GET_ILLNESS_LIST_SUCCESS,
    payload: {
      illnessList,
    },
  };
};

/**
 * dispatch action to add a value to the illness list
 * @param {Array<String>} values The list of value to add in to the list of illness
 */
export const addIllness = (values) => {
  console.log('add illness');
  return {
    type: ADD_ILLNESS,
    payload: values,
  };
};

/**
 * dispatch action to remove a value to from the illness list
 * @param {String} value The value to remove in to the list of illness
 */
export const removeIllness = (value) => {
  return {
    type: REMOVE_ILLNESS,
    payload: value,
  };
};
