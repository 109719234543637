import {
  GET_ILLNESS_LIST_LOADING,
  GET_ILLNESS_LIST_SUCCESS,
  GET_ILLNESS_LIST_ERROR,
  ADD_ILLNESS,
  REMOVE_ILLNESS,
} from "constants/ActionTypes";

const INITIAL_STATE = {
  illnessList: [],
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ILLNESS_LIST_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case GET_ILLNESS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        illnessList: action.payload.illnessList,
      };

    case GET_ILLNESS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADD_ILLNESS:
      console.log("add illness from reducer");
      return {
        ...state,
        illnessList: [...state.illnessList, ...action.payload],
      };

    case REMOVE_ILLNESS:
      let tempListDelete = state.illnessList.filter((elem) => {
        return elem.toLowerCase() !== action.payload.toLowerCase();
      });
      return {
        ...state,
        illnessList: [...tempListDelete],
      };

    default:
      return state;
  }
};
